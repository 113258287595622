import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { Code } from "./src/components/Code/Code";
import { preToCodeBlock } from "mdx-utils";

const components = {
  pre: preProps => {
    const props = preToCodeBlock(preProps);
    if (props) {
      return <Code {...props} />;
    } else {
      // it's possible to have a pre without a code in it
      return <pre {...preProps} />;
    }
  }
};
export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
);
