import React from "react";
import Highlight, { defaultProps, Language } from "prism-react-renderer";
import Prism from "prismjs";
import "prismjs/components/prism-typescript";
import "prismjs/components/prism-diff";
import "prismjs/components/prism-bash";

// See https://www.christopherbiscardi.com/post/codeblocks-mdx-and-mdx-utils

type CodeProps = {
  codeString: string;
  language: Language;
};

export const Code = ({ codeString, language }: CodeProps) => (
  <Highlight
    {...defaultProps}
    Prism={Prism}
    code={codeString}
    theme={undefined}
    language={language}
  >
    {({ className, style, tokens, getLineProps, getTokenProps }) => (
      <pre className={className} style={style}>
        <code className={className} style={style}>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </code>
      </pre>
    )}
  </Highlight>
);
