// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---content-pages-about-mdx": () => import("./../../content/pages/about.mdx" /* webpackChunkName: "component---content-pages-about-mdx" */),
  "component---content-pages-connect-mdx": () => import("./../../content/pages/connect.mdx" /* webpackChunkName: "component---content-pages-connect-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-photos-tsx": () => import("./../../src/pages/photos.tsx" /* webpackChunkName: "component---src-pages-photos-tsx" */),
  "component---src-templates-photo-tsx": () => import("./../../src/templates/Photo.tsx" /* webpackChunkName: "component---src-templates-photo-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../src/templates/Post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

